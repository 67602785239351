import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { PhotoSwipeGallery } from 'react-photoswipe'
let options = {
    //http://photoswipe.com/documentation/options.html
}
  
let getThumbnailContent = item => {
    return (
        <div
            className="gallery-item"
            itemProp="associatedMedia"
            itemScope=""
            itemType="http://schema.org/ImageObject"
        >
            <a data-size="750x1002" href={item.src} itemProp="contentUrl">
            <img
                alt="..."
                className="vertical-image img-rounded img-responsive"
                itemProp="thumbnail"
                src={item.src}
            />
            </a>
            <figcaption className="gallery-caption" itemProp="caption description">
            {item.title}
            </figcaption>
      </div>
    )
}
const photoSwipeItems = [
    {
      src: require("assets/images/galeria/1.jpg"),
      title: "",
      w:"1500",
      h:"1000"
    },
    {
      src: require("assets/images/galeria/5.jpg"),
      title: "",
      w:"1500",
      h:"1000"
    },
    {
      src: require("assets/images/galeria/3a.jpg"),
      title: "",
      w:"1500",
      h:"1000"
    },
    {
      src: require("assets/images/galeria/4a.jpg"),
      title: "",
      w:"1500",
      h:"1000"
    },
    {
      src: require("assets/images/galeria/5a.jpg"),
      title: "",
      w:"1500",
      h:"1000"
    },
    {
      src: require("assets/images/galeria/6a.jpg"),
      title: "",
      w:"1500",
      h:"1000"
    },
    {
      src: require("assets/images/galeria/7.jpg"),
      title: "",
      w:"1500",
      h:"1000"
    },
    {
      src: require("assets/images/galeria/8.jpg"),
      title: "",
      w:"1500",
      h:"1000"
    },
    {
        src: require("assets/images/galeria/9.jpg"),
        title: "",
        w: "1280",
        h: "822"
    },
    {
        src: require("assets/images/galeria/10.jpg"),
        title: "",
        w: "1280",
        h: "822"
    },
    {
        src: require("assets/images/galeria/11.jpg"),
        title: "",
        w: "1280",
        h: "822"
    },
    // {
    //     src: require("assets/images/galeria/12a.jpg"),
    //     title: "",
    //     w: "1280",
    //     h: "822"
    // },

  ];
const Galeria = () => {
    return(
        <div className="section text-center pt-5" id="galeria">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title">Galería</h2>
                    </Col>
                </Row>
                <Row>
                    <PhotoSwipeGallery
                        items={photoSwipeItems}
                        options={options}
                        thumbnailContent={getThumbnailContent}
                    />
                </Row>
            </Container>
        </div>
    )
}
export default Galeria