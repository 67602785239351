
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel'

import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import Index from "views/Index.js";

const options = {
  autoConfig: false, 
  debug: true, 
}

ReactPixel.init('389530792116510', {}, options);
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

ReactDOM.render(
  <BrowserRouter>
    <Route path="/" render={(props) => <Index {...props} />} />
  </BrowserRouter>,
  document.getElementById("root")
);
