import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import { Link } from 'react-router-dom'
// core components
const items = [
  {
    src: "url(" + require("assets/images/header/banner1.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title">Costa Terao</h1>
            <h5>
              Un espectacular proyecto en la Isla de Chiloé inmerso en un escenario natural único. 
            </h5>
            <h3>ETAPA 1 · 100% VENDIDA</h3>
            <br />
            
            <div className="buttons">
              <Button
                className="btn-outline-neutral"
                color="white"
                to="/#contact-us"
                tag={Link}
              >
                Contáctanos
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src: "url(" + require("assets/images/header/banner2.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title">Costa Terao</h1>
            <h5>
              Vistas insuperables a la Bahía de Terao e Isla Lemuy.
            </h5>
            <h3>ETAPA 1 · 100% VENDIDA</h3>
            <br />
            
            <div className="buttons">
              <Button
                className="btn-outline-neutral"
                color="white"
                to="/#contact-us"
                tag={Link}
              >
                Contáctanos
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src: "url(" + require("assets/images/header/banner3.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title">Costa Terao</h1>
            <h5>
              Más de 8 hectáreas de reserva natural con bosque nativo a orillas del mar y playa de más de 200 metros.
            </h5>
            <h3>ETAPA 1 · 100% VENDIDA</h3>
            <br />
            <div className="buttons">
              <Button
                className="btn-outline-neutral"
                color="white"
                to="/#contact-us"
                tag={Link}
              >
                Contáctanos
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
];

function HeaderApp() {
  
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  
  return (
    <>
      <div id="home">
        <div className="header-3">
          <div className="page-carousel">
            <div className="filter" />
            <Carousel interval="7000" activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map((item) => {
                return (
                  <CarouselItem
                    
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <div
                      className="page-header"
                      style={{ backgroundImage: item.src }}
                    >
                      <div className="filter" />
                      <div className="content-center">{item.content}</div>
                    </div>
                  </CarouselItem>
                );
              })}
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <span className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <span className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </div>
        </div>
        </div>
    </>
  );
}

export default HeaderApp;
