import React from "react";
import { Container, Row, Col } from "reactstrap";

function Ubicacion() {
  return (
    <>
      <div className="section section-feature cd-section" id="ubicacion">
        {/* ********* FEATURES 4 ********* */}
        <div className="features-4">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Ubicación</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="ubicacion-container">
                    <iframe className="ubicacion-iframe" title="ubicacion" src="https://www.google.com/maps/d/u/0/embed?mid=1RN06jGezfggHs4gTyw6f_UalJLp7_E8N"></iframe>
                </div>
              </Col>
              {/* <Col className="offset-1" md="4">
                <div className="info info-horizontal pt-0 pt-sm-4">
                  <div className="icon icon-info">
                    <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Al Norte</h4>
                    <ul className="pl-0">
                      <li>Playa Santa Bárbara: 1,5 km</li>
                      <li>Cerro El Vilcún (cuevas con pinturas rupestres): 5 Km</li>
                      <li>Aeropuerto: 10 min</li>
                      <li>Sendero Alerces Milenarios (Parque Pumalín): 35 min</li>
                      <li>Caleta Gonzalo (Fiordo Reñihue, transbordador ruta bimodal): 50 min</li>
                    </ul>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-success">
                    <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Al Sur</h4>
                    <ul className="pl-0">
                      <li>Chaitén a 7,5 Kms directo por ruta 7: 8 min </li>
                      <li>El Amarillo (Parque Pumalín)</li>
                      <li>Sendero Volcán Michimahuida: 30 min</li>
                      <li>Termas El Amarillo: 45 min</li>
                      <li>Lago Yelcho: 55 min</li>
                      <li>Futaleufú – Río Futaleufú: 3 hrs</li>
                      <li>Puyuhuapi (termas, parque nacional Queulat): 5 hrs</li>
                    </ul> 
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 4 ********* */}

      </div>
    </>
  );
}

export default Ubicacion;
