import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import SagConaf from '../../assets/images/sag-conaf.png'
const Proyecto = () => {
    return(
        <>
            <div className="section section-feature cd-section">
            {/* ********* FEATURES 1 ********* */}
            <div className="features-1" id="elproyecto">
                <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">El Proyecto</h2> 
                    <h5 className="description">
                      Descubre Costa Terao, un exclusivo proyecto inmerso en un impresionante bosque nativo en el
                      corazón de la mágica isla de Chiloé.
                    </h5>
                    <h5 className="description">
                      Un lugar donde la naturaleza se desborda, con maravillosas vistas y más de 8 hectáreas de Reserva
                      Natural, donde podrás disfrutar de los estratégicos miradores y senderos de trekking para hacer
                      paseos únicos y acceder a los más de 200 metros lineales de playa del loteo.
                    </h5>
                    <h5 className="description">
                      Vive tú lugar en el Sur de Chile a sólo 35 minutos del aeropuerto de Castro y a 7 minutos de
                      Chonchi, en una estratégica ubicación para recorrer y descubrir la isla.
                    </h5>
                    </Col>
                </Row>
                </Container>
            </div>
        {/* ********* FEATURES 5 ********* */}
        <div
          id="descripcion"
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/images/sections/descripcion.jpg") +
              ")",
          }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center">
                  Descripción
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-map-pin"/>
                  </div>
                  <h4 className="title text-white">Ubicación</h4>
                  <p className="text-white">
                    A solo 35 minutos del aeropuerto de Castro.<br/>
                    A 7 Minutos de Chonchi.<br/>
                    Acceso directo a carretera pavimentada.<br/>
                    A pasos de la laguna Tarahuin
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-tree-02" />
                  </div>
                  <h4 className="title text-white">Características</h4>
                  <p className="text-white">
                    Áreas comunes enfocadas en la naturaleza y su uso familiar.<br/>
                    Terrenos desde 5.000 mt2.<br/>
                    Plan de Manejo, Ley 20.283 sobre Recuperación del Bosque Nativo y Fomento.
                  </p>
                  <div>
                    <img style={{"width":"245px"}} src={SagConaf} alt="img-sag-conaf"/>
                    
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-park" />
                  </div>
                  <h4 className="title text-white">Entorno</h4>
                  <p className="text-white">
                  72 hectáreas de bosque nativo de espectaculares Canelos, Arrayanes, Lumas y Quillayes entre
                  otros.<br/>
                  8 hectáreas de Reserva Natural.<br/>
                  200 metros lineales de playa.<br/>
                  Insuperables vistas a la Bahía de Terao, isla Lemuy y el volcán Michimahuida de Chaitén.
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-currency-dollar" />
                  </div>
                  <h4 className="title text-white">Valor</h4>
                  <p className="text-white">
                    Los precios van desde 792 UF.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 5 ********* */}
        {/* ********* FEATURES 4 ********* */}
        {/* <div className="features-4" id="urbanizacion">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Urbanización</h2>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="ipad-container">
                  <img
                    alt="..."
                    src={require("assets/images/fotourba.jpg")}
                  />
                </div>
              </Col>
              <Col className="offset-1" md="4">
                <div className="info info-horizontal pt-4">
                  <div className="icon icon-info">
                    <i aria-hidden={true} className="nc-icon icon-property-location" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Loteo</h4>
                    <p>
                      94 sitios de 5.000 m2 y más.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal pt-3">
                  <div className="icon icon-success">
                    <i aria-hidden={true} className="nc-icon icon-mortgage" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Entrega</h4>
                    <p>
                      Sitios urbanizados a fines de 2020
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal pt-3">
                  <div className="icon icon-danger">
                    <i aria-hidden={true} className="nc-icon nc-tie-bow" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Urbanización</h4>
                    <ul className="ml-0 pl-3">
                      <li className="texto-normal">Camino estabilizado y compactado.</li>
                      <li className="texto-normal">Agua GRATIS para cada sitio</li>
                      <li className="texto-normal">Luz para cada sitio con postación aérea grupo Saesa</li>
                      <li className="texto-normal">Mirador Panorámico</li>
                      <li className="texto-normal">Senderos de Trekking</li>
                      <li className="texto-normal">Zona de reciclaje y equipamiento comunitario</li>
                    </ul>
                  </div>
                </div>
                <div className="info info-horizontal pt-3">
                  <div className="icon icon-info">
                    <i aria-hidden={true} className="nc-icon icon-currency-dollar" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Forma de Pago</h4>
                    <p>
                      Solicita mayor información.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        <Promo />
      </div>
    </>

    )
}
export default Proyecto

function Promo(){
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.body.classList.add("section-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("section-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return(
    <div className="section section-icons section-gray" id="promocion" >
      <Container>
        <Row className="justify-content-center">
            <Col className="text-center" md="8">
              
                <h2 className="title">PROMOCIÓN</h2>
                <div className="icons-nucleo">
                  <i className="nc-icon icon-present add-animation" />
                </div>
            
              
              <h5 className="description">
                MÁS DEL 50% VENDIDO, INICIO DE VENTA DE LA 2DA ETAPA, PREGUNTA POR LAS PROMOCIONES EXISTENTES Y CONDICIONES ESPECIALES DE PAGO.
              </h5>
            </Col>
        </Row>
      </Container>
    </div>
  )
}
