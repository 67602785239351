import React from "react";
import Menu from "components/Menu";
import HeaderApp from "components/HeaderApp";
import Proyecto from 'components/Proyecto'
import Masterplan from 'components/Masterplan'
import Ubicacion from 'components/Ubicacion'
import Video from 'components/Video'
import Galeria from 'components/Galeria'
import Contacto from 'components/Contacto'
import Footer from "components/Footer"


function Index() {
  document.documentElement.classList.remove("nav-open");

  
  React.useEffect(() => {
    document.body.classList.add("section-page");
    
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    return function cleanup() {
      document.body.classList.remove("section-page");
    };
  });

  
  return (
    <>
      <Menu />
      <HeaderApp />
      <Proyecto />
      <Masterplan />
      <Video />
      <Galeria />
      <Ubicacion />
      <Contacto />
      <Footer />
    </>
  );
}

export default Index;
