import Styled from 'styled-components'

export const CardContacto = Styled.div`
    padding-top:15px;
    display: grid;
    grid-template: [top] 1fr [row1] 1fr [bottom] / [izq] 25px [col1] 1fr [der];
    height: 75px;
    width:100%;
    @media(max-width:768px){
        padding-left:20px;
    }
`
export const Nombre = Styled.div`
    grid-column: col1/der;
    grid-row:top/row1;
    margin: auto 0;
    padding-left:10px;
    
    
`
export const Telefono = Styled.div`
    grid-column: izq/der;
    grid-row: row1/bottom;
    display:grid;
    grid-template: [top] 1fr [bottom] / [izq] 25px [col1] 1fr [der];
    margin: auto 0;
`